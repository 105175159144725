import * as En from 'blockly/msg/en'

export default {
  ...En,
  CATEGORY_CONTROLS: 'Controls',
  CATEGORY_LOGIC: 'Logic',
  CATEGORY_MATH: 'Math',
  CATEGORY_VARIABLES: 'Variables',
  CATEGORY_FUNCTIONS: 'Functions',
  CATEGORY_ACT: 'Act',
  CATEGORY_INTERACTIONS: 'Interactions',
  CATEGORY_DATA: 'Data',
}
